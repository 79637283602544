<template>
  <div class="container">
    <section class="top">
      <div class="row">
        <div class="col-12">
          <h4>Validação de Documentos</h4>
          <br />
          <br />
          <div class="row" formArrayName="anexos">
            <div
              class="col-lg-12"
              v-if="
                !qrcodeOn && !insertToken && !documentValid && !documentInvalid
              "
            >
              <input
                type="file"
                class="inputAnexos"
                id="inputAnexos"
                accept="application/pdf"
                @change="anexoChangeEvent($event.target.files)"
              />
              <div
                class="card-block d-flex justify-content-center align-items-center flex-column"
              >
                <h3 class="text-center">Valide seu documento</h3>
                <h6 class="text-center mt-3">
                  Envie o arquivo PDF com o Certificado de Assinaturas
                </h6>
                <a class="mt-3 code" @click="onScan()"
                  >Quero validar por QrCode</a
                >

                <div
                  class="col-12 d-flex justify-content-center align-items-center mt-5 borderDoc"
                >
                  <button
                    type="button"
                    class="btn btn-success btn-sm btn-doc"
                    @click="alterarAnexo(-1)"
                  >
                    <i class="fa fa-plus-circle"></i>&nbsp;Novo Documento
                  </button>
                  <p class="m-0 ml-3 d-flex nameFile">
                    {{
                      anexos[0]?.arquivo?.nome
                        ? anexos[0]?.arquivo?.nome
                        : 'Nenhum arquivo escolhido'
                    }}
                  </p>
                </div>

                <div
                  class="col-10 d-flex justify-content-center align-items-center mt-5 section-verify"
                >
                  <button
                    type="submit"
                    class="btn btn-primary btn-verify"
                    @click="onSubmit()"
                  >
                    <i class="fa fa-save"></i>
                    Verificar validade do documento
                  </button>
                </div>
              </div>
            </div>

            <div
              class="col-lg-12"
              :style="{ display: qrcodeOn ? 'block' : 'none' }"
            >
              <div
                class="card-block d-flex justify-content-center align-items-center flex-column"
                id="section-reader"
              >
                <h3 class="text-center">Escaneie o QrCode</h3>
                <h6 class="text-center mt-3">
                  Aponte a camera para o QrCode do documento para realizar a
                  validação.
                </h6>
                <a class="mt-3 code" href="/validar"
                  >Quero validar por arquivo PDF</a
                >
              </div>
            </div>

            <div class="col-lg-12" v-if="insertToken">
              <div
                class="card-block d-flex justify-content-center align-items-center flex-column"
              >
                <h3 class="text-center">Informe o código de segurança</h3>
                <h6 class="text-center mt-3">{{ textCode }}</h6>
                <a class="mt-3 code" v-if="tipoEnvio == 1" @click="sendToken(0)"
                  >Reenviar código via SMS</a
                >
                <a class="mt-3 code" v-if="tipoEnvio == 0" @click="sendToken(1)"
                  >Reenviar código via Email</a
                >
                <div class="form-group mb-30 col-8 p-0 mt-3">
                  <input
                    type="text"
                    id="token"
                    placeholder="Código de Segurança"
                    v-model="token"
                    required
                  />
                </div>
                <div class="form-group mb-0 text-center">
                  <button @click="validarDocumento()" class="custom-button">
                    Enviar
                  </button>
                  <br />
                  <a class="mt-3 code" href="/validar"
                    >Quero validar por arquivo PDF</a
                  >
                </div>
              </div>
            </div>
            <div class="col-lg-12" v-if="documentValid">
              <div class="col-12 text-start">
                <div class="dash-pro-item mb-30 dashboard-widget">
                  <div class="header">
                    <h4 class="title">Informações</h4>
                  </div>
                  <div class="alert alert-success text-uppercase" role="alert">
                    Documento válido!
                  </div>
                  <ul class="dash-pro-body">
                    <li>
                      <div class="info-name text-uppercase">Código</div>
                      <div class="info-value text-uppercase">
                        {{ uniqueId }}
                      </div>
                      <div class="info-name text-uppercase">Tipo Documento</div>
                      <div class="info-value">
                        {{ tipoDocumento }}
                      </div>
                      <div class="info-name text-uppercase">Data Emissão</div>
                      <div class="info-value">
                        {{ dataEmissao | formatDataHora }}
                      </div>
                      <div class="info-name text-uppercase">Leilão</div>
                      <div class="info-value">
                        {{ leilao }}
                      </div>
                      <div class="info-name text-uppercase">Numero Lote</div>
                      <div class="info-value">#{{ numeroLote }}</div>
                      <div class="info-name text-uppercase">Descrição Lote</div>
                      <div class="info-value">
                        {{ tituloLote }}
                      </div>
                      <div class="info-name text-uppercase">Arrematante</div>
                      <div class="info-value text-uppercase">
                        {{ nomeArrematante }}
                      </div>
                      <div class="info-name text-uppercase">
                        Doc. Arrematante
                      </div>
                      <div class="info-value">
                        {{ documentoArrematante }}
                      </div>
                    </li>
                    <li></li>
                  </ul>
                  <div class="col-12 text-center">
                    <a class="mt-3 code" :href="urlDocumento" target="_blank"
                      >Download do documento</a
                    >
                  </div>
                </div>
                <div class="col-12 text-center">
                  <a class="mt-3 code" href="/validar"
                    >Validar Novo Documento</a
                  >
                </div>
              </div>
            </div>
            <div class="col-lg-12" v-if="documentInvalid">
              <div class="col-12 text-start">
                <div class="dash-pro-item mb-30 dashboard-widget">
                  <div class="header">
                    <h4 class="title">Informações</h4>
                  </div>
                  <div class="alert alert-danger text-uppercase" role="alert">
                    Documento inválido!
                  </div>
                  <ul class="dash-pro-body">
                    <li>
                      <div class="info-name text-uppercase">Código</div>
                      <div class="info-value text-uppercase">
                        {{ uniqueId }}
                      </div>
                    </li>
                    <li></li>
                  </ul>
                </div>
                <div class="col-12 text-center">
                  <a class="mt-3 code" href="/validar"
                    >Validar Novo Documento</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
// import { Html5QrcodeScanner } from "html5-qrcode";
import { Html5Qrcode } from 'html5-qrcode';
import moment from 'moment';
import http from '@/service/http/http';
import notification from '@/mixins/notification';
export default {
  data() {
    return {
      titulo: '',
      userCompleted: '',
      anexos: [],
      fileToUpload: File | null,
      anexosbase64: '',
      numeroAdcAnexo: '',
      qrcodeOn: false,
      html5QrcodeScanner: '',
      uniqueId: '',
      email: '',
      token: '',
      emailCheck: true,
      smsCheck: false,
      tipoEnvio: 1,
      insertToken: false,
      textCode: '',
      documentValid: false,
      leilao: '',
      dataEmissao: '',
      tipoDocumento: '',
      nomeArrematante: '',
      documentoArrematante: '',
      tituloLote: '',
      numeroLote: '',
      urlDocumento: '',
    };
  },
  mixins: [notification],
  computed: {
    ...mapGetters(['getPaginasLoading', 'getPaginas']),
  },
  watch: {
    $route: 'fetchData',
  },
  filters: {
    formatDataHora(value) {
      return moment(value).utc().local().format('DD/MM/YYYY');
    },
  },
  methods: {
    ...mapActions(['setPaginas']),
    fetchData: function () {
      if (this.$route.name == 'validacaodocumento') {
        this.qrcodeOn = false;
        this.tokenSend = false;
        this.emailCheck = true;
        this.smsCheck = false;
        this.tipoEnvio = 1;
        this.insertToken = false;
        this.onPdf();
      }
      if (this.$route.name == 'validacaodocumentoid') {
        this.validarDocumento();
      }
    },
    async validarDocumento() {
      const payload = {
        uniqueId: this.uniqueId,
        // uniqueId:'cd2c0a73-e52e-4b51-a033-941c3d09ab41'
      };
      try {
        await http
          .post(`documentolote/validar/qrcode`, payload)
          .then((item) => {
            const informations = item.data.data;
            this.dataEmissao = informations.dataEmissao;
            this.leilao = informations.leilao;
            this.tipoDocumento = informations.tipoDocumento;
            this.uniqueId = informations.uniqueId;
            this.nomeArrematante = informations.nomeArrematante;
            this.documentoArrematante = informations.documentoArrematante;
            this.tituloLote = informations.tituloLote;
            this.numeroLote = informations.numeroLote;
            this.urlDocumento = informations.urlDocumento;
            this.showToast({ title: 'Documento é válido' });
            this.documentValid = true;
            this.insertToken = false;
          });
      } catch (error) {
        this.documentInvalid = true;
        this.showToast({ title: 'Documento é inválido', type: 'error' });
      }
    },

    async sendToken(tipo) {
      this.tipoEnvio = tipo;
      const payload = {
        hash: this.uniqueId,
        tipoEnvio: tipo,
      };

      http
        .post(`DocumentoLote/verificar/qrcode`, payload)
        .then((response) => {
          this.insertToken = true;
          if (tipo == 1) {
            const email = response.data.data.email;
            this.textCode = `O código foi encaminhado ao email: ${email} `;
            this.showToast({ title: 'Código de segurança enviado por email' });
          }
          if (tipo == 0) {
            const telefone = response?.data?.data?.telefone;
            this.textCode = `O código foi encaminhado ao telefone: ${telefone} `;
            this.showToast({ title: 'Código de segurança enviado por sms' });
          }
          this.qrcodeOn = false;
          this.documentValid = false;
          this.documentInvalid = false;
          this.insertToken = true;
        })
        .catch(() => {
          this.showToast({ title: 'QrCode inválido', type: 'warning' });
        });
    },

    async onSubmit() {
      if (this.anexos.length == 0) {
        this.showToast({ title: 'Inserir arquivo', type: 'warning' });
        return;
      }
      const base64 = {
        documentoBase64: this.anexos[0].arquivo.base64,
      };
      await http
        .post(`DocumentoLote/verificar`, base64)
        .then((item) => {
          const informations = item.data.data;
          this.dataEmissao = informations.dataEmissao;
          this.leilao = informations.leilao;
          this.tipoDocumento = informations.tipoDocumento;
          this.uniqueId = informations.uniqueId;
          this.nomeArrematante = informations.nomeArrematante;
          this.documentoArrematante = informations.documentoArrematante;
          this.tituloLote = informations.tituloLote;
          this.numeroLote = informations.numeroLote;
          this.urlDocumento = informations.urlDocumento;
          this.showToast({ title: 'Documento é válido' });
          this.documentValid = true;
          this.insertToken = false;
        })
        .catch(() => {
          this.showToast({
            title: 'Erro ao validar documento',
            type: 'error',
          });
        });
    },
    anexoChangeEvent(anexoInput) {
      this.fileToUpload = anexoInput.item(0);
      this.fileToUpload.name;
      this.fileToUpload.size;
      this.fileToUpload.type;
      const reader = new FileReader();
      reader.readAsDataURL(this.fileToUpload);
      reader.onload = () => {
        this.anexosbase64 = reader.result;
        this.anexosbase64 = this.anexosbase64.replace(
          'data:application/pdf;base64,',
          ''
        );
        const arquivo = {
          arquivoId: 0,
          nome: this.fileToUpload.name,
          base64: this.anexosbase64,
          tipo: this.fileToUpload.type,
          tamanho: this.fileToUpload.size,
          dataCadastro: moment().utc().toISOString(),
        };
        this.atualizarAnexo(arquivo, this.numeroAdcAnexo);
      };
    },

    atualizarAnexo(obj) {
      if (this.anexos.length < 1) {
        this.anexos.push({
          arquivoId: 0,
          nome: '',
          arquivo: obj,
          acao: 'I',
        });
      } else {
        this.anexos = [];
        this.anexos.push({
          arquivoId: 0,
          nome: '',
          arquivo: obj,
        });
      }
    },
    alterarAnexo(i) {
      this.numeroAdcAnexo = i;
      const inputAnexos = document.getElementById('inputAnexos');
      inputAnexos.click();
    },
    onScan() {
      this.qrcodeOn = true;
      var sectionReader = document.getElementById('section-reader');
      let reader = document.createElement('div');
      reader.id = 'reader-qrcode';
      reader.className = 'mt-3 qrcode';
      sectionReader.appendChild(reader);

      const html5QrCode = new Html5Qrcode('reader-qrcode');
      const qrCodeSuccessCallback = (decodedText) => {
        const linkId = decodedText.slice(
          decodedText.length - 36,
          decodedText.length
        );
        this.qrcodeOn = false;
        this.insertToken = false;
        this.uniqueId = linkId;
        // this.sendToken(1);
        html5QrCode.stop().then(() => this.validarDocumento());
      };
      const config = { fps: 10, qrbox: { width: '95%', height: 1000 } };

      html5QrCode.start(
        { facingMode: 'environment' },
        config,
        qrCodeSuccessCallback
      );
    },
    onPdf() {
      this.qrcodeOn = false;
      this.insertToken = false;
      this.documentValid = false;
      this.documentInvalid = false;
      var reader = document.getElementById('reader-qrcode');
      reader.parentNode.removeChild(reader);
      this.$router.push('validar');
    },
  },
  mounted() {
    if (this.$route.name == 'validacaodocumentoid') {
      this.uniqueId = this.$route.params.id;
      this.validarDocumento();
    }
  },
};
</script>

<style scoped>
.top {
  padding: 150px 20px 0;
}

.new-campo {
  display: flex;
  justify-content: space-between;
}

.campo {
  display: flex;
  flex-wrap: wrap;
}

.remover {
  display: flex;
  align-items: flex-end;
}

.image-wrap {
  padding: 10px;
  border: solid 1px #e0e0e0;
  display: inline-block;
}

.row-img {
  width: 25%;
}

.img-table {
  width: 40%;
}

.img-space {
  border: dashed 1px #d3d3d3;
  border-radius: 8px;
  background-color: #f2f2f2;
  height: auto;
  width: 100%;
  padding: 10px;
  min-height: 175px;
}

.img-space img {
  display: block;
  width: 100%;
  height: 100%;
  margin: auto;
  cursor: pointer;
}

.fileInputProfileWrap {
  width: 100%;
  height: auto;
}

.fileInputProfile {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 99;
  opacity: 0;
}

input[type='file'] {
  display: none;
}

.labelArquivo {
  padding: 10px;
  background-color: #333;
  width: 50%;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  margin-top: 10px;
  cursor: pointer;
  border-radius: 5px;
}

.flex {
  display: flex;
  align-items: center;
}

.foto-buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.deletarFoto {
  margin-left: 10px;
  width: 50%;
  height: 2.9em;
}

.labelRemove {
  padding: 10px;
  color: #fff;
  width: 50%;
  text-transform: uppercase;
  text-align: center;
  margin-top: 10px;
  cursor: pointer;
  border-radius: 5px;
  margin-left: 10px;
}

.btn-black {
  background-color: #333;
  color: #fff;
}

.btn-primary {
  height: 4.5vh;
}

.saveCancel {
  margin-right: 1em;
  height: auto;
}

.has-error {
  border: 1px solid red;
}

.borderDoc {
  min-height: 70px;
  border: 1px dashed #d1d1d1;
}

.code {
  color: #428bca;
  cursor: pointer;
}

.btn-doc {
  width: 40%;
  height: 10%;
  font-size: 0.8rem;
}

.info-name {
  font-weight: 700;
  padding-top: 5px !important;
  width: 170px !important;
}

.info-value {
  width: calc(100% - 170px) !important;
  padding-top: 5px !important;
}

.btn-verify {
  width: 60%;
  height: 10%;
  font-size: 0.8rem;
}
.qrcode {
  min-width: 300px;
  width: 60%;
}
@media (max-width: 1000px) {
  .qrcode {
    min-width: 300px;
    width: 50%;
  }

  .section-verify {
    width: 100% !important;
    padding: 0%;
    max-width: 100%;
  }

  .btn-verify {
    width: 100%;
  }

  .btn-doc {
    width: 100%;
    margin: 15px 0;
  }

  .nameFile {
    margin: 15px 0px !important;
  }

  .borderDoc {
    text-align: center;
    flex-wrap: wrap-reverse;
  }
}

@media (max-width: 575px) {
  .info-name {
    width: 100% !important;
  }

  .info-value {
    width: 100% !important;
    padding-top: 0 !important;
  }
}
</style>
